import React from "react"

import Layout from "../components/layout"
import marquee3 from "../images/marquee3-min.jpg"

import "./pricing.css"

const PricingPage = () => {
  return (
    <Layout imageUrl={marquee3} pageTitle="Pricing">
      <div className="content">
        <div className="pricing-block">
          <h1>2025</h1>
          <div className="pricing">
            <div className="pricing-detail">
              <h2>March, April & October</h2>
              <h3>
                Friday - Sunday & Bank holidays
                <br />
                <i>£5300</i>
              </h3>
              <h3>
                Monday - Thursday <br />
                <i>£4500</i>
              </h3>
            </div>
            <div className="pricing-detail">
              <h2>May - September</h2>
              <h3>
                Friday - Sunday & Bank holidays
                <br />
                <i>£6300</i>
              </h3>
              <h3>
                Monday - Thursday <br />
                <i>£5500</i>
              </h3>
            </div>
          </div>
        </div>
        <div className="pricing-block">
          <h1>2026</h1>
          <div className="pricing">
            <div className="pricing-detail">
              <h2>March, April & October</h2>
              <h3>
                Friday - Sunday & Bank holidays
                <br />
                <i>£5500</i>
              </h3>
              <h3>
                Monday - Thursday <br />
                <i>£4500</i>
              </h3>
            </div>
            <div className="pricing-detail">
              <h2>May - September</h2>
              <h3>
                Friday - Sunday & Bank holidays
                <br />
                <i>£6500</i>
              </h3>
              <h3>
                Monday - Thursday <br />
                <i>£5500</i>
              </h3>
            </div>
          </div>
        </div>
        <div className="included-lists">
          <div>
            <h2 className="pricing-headers">What's included?</h2>
            <div className="included">
              Exclusive use of the Venue & Chapel <br />
              One nights stay in the boathouse <br />
              Full exclusive use of the gardens <br />
              Indoor tables and chairs <br />
              Outdoor tables and chairs <br />
              Stretch tent and festoon lighting over the patio <br />
              Portable Bose sound system <br />
              Over night parking
            </div>
          </div>
          <div>
            <h2 className="pricing-headers">What's not included?</h2>
            <div className="not-included">
              Catering <br />
              Decorations <br />
              Entertainment <br />
              Table linen and everything on top <br />
              All your personal touches
            </div>
          </div>
        </div>
        <div className="optional-extras">
          <h2>Optional extras</h2>
          Civil ceremony set-up fee <i>£300</i> <br />
          Additional night in the boathouse <i>£200</i> <br />
          Stretch tent in the garden <i>£700</i> <br />
          Second day festivities <i>£2500</i>
        </div>
      </div>
    </Layout>
  )
}
export default PricingPage
